<script>
import { useHead } from '@vueuse/head';
import { ref, inject } from 'vue';
import words from '../assets/rpgs.json';

export default {
  setup() {
    const axios = inject('axios');

    useHead({
      title: 'RPG Book Club',
    });

    const games = words.filter((x) => x.Game !== 'edit');

    const name = ref(null);
    const extract = ref(null);
    const image = ref(null);
    const link = ref(null);

    const inspire = async () => {
      let summary;
      try {
        do {
          const title = games[Math.floor(Math.random() * games.length)].Link.split('/wiki/')[1];
          const response = await axios.get(`https://en.wikipedia.org/api/rest_v1/page/summary/${title}`);
          summary = response.data;
        }
        while (!('originalimage' in summary));

        name.value = summary.displaytitle.replace('<i>', '').replace('</i>', '');
        extract.value = summary.extract;
        image.value = summary.originalimage.source;
        link.value = summary.content_urls.desktop.page;
      } catch (error) {
        console.log(error);
      }
    };

    return {
      inspire,
      name,
      extract,
      image,
      link,
    };
  },
};

</script>

<template>
  <div class="h-40 sm:h-64 lg:h-72">
    <img src="@/assets/bookclubbannerbiggerbiggerbigger.png" class="object-cover w-full h-full" alt="Image alt text" />
  </div>
  <div>
    <article class="mx-auto max-w-3xl font-nunito mt-12 px-6 sm:px-8">
      <h1 class="font-nunito font-bold text-3xl mb-8 sm:text-6xl">📚 RPG Book Club</h1>
      <div class="text-lg">
        <div class="mb-8">
          <p>A club for trying out all the RPGs we never get to play.</p>
          <p>If you’ve listened to the One Shot podcast or seen New Game Who Dis, it’s just that.</p>
        </div>
        <div class="mb-8">
          <h2 class="mb-2">Format:</h2>
          <ul class="list-disc ml-8">
            <li>There is a Librarian</li>
            <li>Whoever is Librarian gets to pick and prepare the game we play</li>
            <li>The game should run for 1-4 sessions</li>
            <li>We learn, we play, we have fun</li>
            <li>We rotate Librarian after each game so everyone gets a chance to pick and run games</li>
          </ul>
        </div>
        <div class="mb-8">
          <h2 class="mb-2">Good game examples:</h2>
          <ul class="list-disc ml-8">
            <li>One-page RPGs</li>
            <li>Indie RPGs</li>
            <li>RPG starter sets </li>
            <li>Short campaigns with pre built characters</li>
            <li>Systems in development that you want to test</li>
            <li>Try out a new VTT</li>
            <li>A level 20 pathfinder party vs a Treerazer</li>
            <li>"Ironically" playing My Little Pony 5e mods</li>
          </ul>
        </div>
        <div class="mb-8">
          <p>Anything goes as long as we can sit around and play some new games together.</p>
        </div>

        <div class="p-3 bg-yellow-100 rounded-lg">
          <div class="w-full flex justify-center">
            <button @click="inspire()" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-lg font-bold rounded-md shadow-lg text-gray-600 bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
              Roll for inspiration!
            </button>
          </div>

          <div v-if="name" class="py-6">
            <div class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
              <div class="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                <img class="mx-auto object-cover shadow-lg rounded-lg" :src="image" alt="" />
              </div>
              <div class="sm:col-span-2">
                <div class="space-y-4">
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <h3>{{ name }}</h3>
                  </div>
                  <div class="text-lg">
                    <p>{{ extract }}</p>
                  </div>
                  <div class="text-lg leading-6 font-medium underline cursor-pointer">
                    <a class="text-sm underline" :href="link">More info</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-8">
          <p>If this sounds like your thing, join the Discord!</p>
        </div>
        <div class="hidden sm:block mb-24">
          <div class="bg-indigo-400 border-4 border-indigo-400 w-full shadow-lg rounded-lg">
            <div class="flex">
              <div class="w-2/3 p-6 text-white">
                <h1 class="font-bold">Join the RPG Book Club Discord Server!</h1>
                <a class="text-sm underline" href="https://discord.gg/Dgc22yUY">Join link</a>
              </div>
              <div class="w-1/3">
                <div class="h-full sm:h-32">
                  <img src="@/assets/book.jpeg" class="object-cover w-full h-full rounded-r-lg" alt="Image alt text" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block sm:hidden mb-24">
          <div class="bg-indigo-400 border-4 border-indigo-400 w-full shadow-lg rounded-lg">
            <div class="flex-col">
              <div class="w-2/3 p-6 text-white">
                <h1 class="font-bold">Join the RPG Book Club Discord Server!</h1>
                <a class="text-sm underline" href="https://discord.gg/Dgc22yUY">Join link</a>
              </div>
              <div class="">
                <div class="w-full h-32">
                  <img src="@/assets/book.jpeg" class="object-cover w-full h-full rounded-b-lg" alt="Image alt text" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </article>
  </div>
</template>

<style>
  li {
    @apply mb-1;
  }
</style>
