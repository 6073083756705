<template>
  <div>
      <div class="py-6 mx-auto w-full bg-white text-gray-700 dark:bg-blu dark:text-bludark">
        <main>
          <router-view />
        </main>
      </div>

  </div>
</template>
