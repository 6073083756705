import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";

const head = createHead();

const app = createApp(App).use(head).use(router);
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.mount("#app");
